<template>
 <v-theme-provider dark>
  <base-section
    id="sanitarycleaner"
    class="accent"
    space="36"
  >
    <v-spacer />

    <div v-if="$route.name=='위생용 세척제'">
      <v-tabs
        class="hidden-sm-and-down"
        optional
        background-color="transparent"
      >
        <v-tab
          v-for="(name, i) in items"
          :key="i"
          :to="{ name }"
          :exact="name === 'Home'"
          :ripple="false"
          class="font-weight-bold"
          min-width="96"
          text
        >
          {{ name }}
        </v-tab>
      </v-tabs>
    </div>

    <v-container>
      <base-section-heading title="위생용 세척제" />
      <v-card
          max-width="1080"
          class="mx-auto"
        >
          <v-list
          class="accent"
          three-line
          >
            <template v-for="(info, index) in iteminfos">
              <v-divider
                v-if="info.divider"
                :key="index"
              ></v-divider>

              <v-list-item
                v-else
                :key="info.title"
              >
                <v-list-item-avatar>
                  <v-img :src="info.avatar"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="info.title"></v-list-item-title>
                  <v-card-text v-html="info.subtitle"> </v-card-text>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
    </v-container>
  </base-section>
 </v-theme-provider>
</template>

<script>
  export default {
    name: 'Sectionsanitarycleaner',
    drawer: null,
    data: () => ({
      drawer: null,
      items: [
        '자동식기 세척용',
        '주방용 세척제',
        '위생용 세척제',
        '세탁 및 카페트용',
      ],
      inset: false,
      iteminfos: [
        { header: 'Today' },
        {
          avatar: '',
          title: '손세척',
          subtitle: '&mdash; 식품산업 및 주방의 위생에 있어 작업장 뿐만 아니라 작업자의 위생 또한 매우 중요한 요소입니다.<br>㈜남강의 핸드클리너는 살균 및 세척은 물론 피부보호 작용을 통하여 사용자의 안전을 고려하였으며, 로션타입으로 사용이 편리 합니다.<br>사용 후 손의 끈적함이 없이 상쾌함을 느끼실 수 있습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '배수구 막힘 전용 세척제',
          subtitle: '&mdash; 주방의 배수파이프는 기름때와 음식 찌꺼기로 쉽게 막히게 되며, 악취는 물론 주방의 위생에 많은 악영향을 끼칩니다.<br>이에 작업을 마친 이후 본 세제 원액을 소량씩 투여하면 사전에 배수파이프 막힘을 예방 할 수 있습니다.<br>가격이 저렴하며 세척 및 살균 효과가 뛰어납니다.<br>',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '변기 및 하수구 악취제거용 세척제',
          subtitle: '&mdash; 변기에 누런 스케일이 끼면 아취가 나며 비위생적입니다.<br> 이에 본 세제를 정규적으로 이용하여 청소하시면, 변기에 스케일이 발생하지도 않으며, <br>배관 속의 균도 완전히 멸균되어 위생상태를 유지 할 수 있습니다.<br>특히 병원, 호텔 등에서 사용 시에는 따로 소독 약품읠 쓰실 필요가 없으며, <br>한번의 작업으로 세척, 살균작업을 쉽게 끝내실 수 있습니다.',
        },
        { divider: true, inset: true },
        {
          avatar: '',
          title: '타일 및 스테인레스 용 연마세제',
          subtitle: '&mdash; 변색된 스테인레스 재질의 기구(식기,조리기구,싱크대 등) 및 타일 재질의 바닥이나 벽 등에 문질러 닦는 강력세제 입니다.<br>세척 및 악취제거에 탁월하며, 싱크대 등 스테인레스 제품에 사용하실 경우 광택효과가 뛰어 납니다.<br>',
        },
      ],
    }),
    provide: {
      theme: { isDark: false },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },

    },

  }
</script>
<style scoped>
.v-subheader {
    font-size: 18px;
    color: coral !important;
}
.v-list-item__title {
    font-size: 18px;
    color: coral !important;
}
</style>
